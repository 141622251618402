/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:6e6c621c-3c58-439a-b970-1a1f601aaa31",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_qLcSJqm2P",
    "aws_user_pools_web_client_id": "6luu7sooglvtsu5ci1kqsa660s",
    "oauth": {}
};


export default awsmobile;
